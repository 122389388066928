@media screen and (min-width: 1024px) and (max-width: 1180px) {
    .grid12 .w1,
    .grid12 .w2,
    .grid12 .w3,
    .grid12 .w4,
    .grid12 .w5,
    .grid12 .w6,
    .grid12 .w7,
    .grid12 .w8,
    .grid12 .w9,
    .grid12 .w10,
    .grid12 .w11,
    .grid12 .w12 {
        display: block;

        width: auto;
        margin: 0;
    }

    .header-menus {
        display: none !important;
    }

    .whitebox {
        width: 100%;
    }

    .community-box {
        margin-bottom: 2em;
    }

    .community-box {
        width: 100%;
    }
}