@media screen and (max-width: 1023px) {
    html * {
        max-height: 999999px;
    }

    body {
        -webkit-text-size-adjust: none;
        max-width: 100vw !important;
    }

    p {
        text-align: justify;
    }

    .editor-content table {
        width: 100% !important;
    }

    .editor-content td {
        display: block;
        box-sizing: border-box;
        width: 100% !important;
    }

    .editor-content table.table-two,
    .editor-content table.table-two-border {
        width: 100%;
        box-sizing: border-box;
    }

    .editor-content table.table-two td,
    .editor-content table.table-two-border td {
        display: table-cell;
        width: 50% !important;
        box-sizing: border-box;
        padding: 5px;
    }

    .editor-content table.table-two td img,
    .editor-content table.table-two-border td img {
        max-width: 100%;
    }

    .video_view__box .operation_right {
        display: block;
        float: none;
        margin-top: 0;
    }

    .grid12 .w1,
    .grid12 .w2,
    .grid12 .w3,
    .grid12 .w4,
    .grid12 .w5,
    .grid12 .w6,
    .grid12 .w7,
    .grid12 .w8,
    .grid12 .w9,
    .grid12 .w10,
    .grid12 .w11,
    .grid12 .w12 {
        display: block;

        width: auto;
        margin: 0 !important;
    }

    .photo_show__box {
        padding-bottom: 50px;
    }

    .photo_show__box .btns {
        margin-top: 0;
    }

    .photo_show__box .btns a {
        margin: 0.8em 0;
    }

    .photo_show__items {
        margin-bottom: 2em;
    }

    .photo_show__items .item {
        margin: 10px;
        display: block;
        text-align: center;
    }

    .photo_show__items .item img.i {
        max-width: 100%;
        display: block;
        margin: auto;
    }

    .photo_show__box .surgeon .btns {
        float: none;
        display: block;
        margin: 1.3em 0;
    }

    .myDialog .myDialog-close {
        right: 0 !important;
    }

    #maintable tbody {
        display: block;
    }

    #maintable #mcenter {
        min-width: 0;
    }

    .table_wrap {
        overflow: hidden;
        overflow-x: scroll;
        max-width: 100%;
        -webkit-overflow-scrolling:touch;
    }

    .table_wrap table td {
        display: table-cell;
        width: auto !important;
    }

    .editor-content {
        word-wrap: break-word;
    }

    .myselect-data-close {
        font-size: 30px;
    }

    .slide-menu {
        position: fixed;
        top: 0;
        right: 100%;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .slide-menu.opened {
        right: 0;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slide-menu.closed {
        right: 100%;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slide-menu .toggle {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        background: #105c8f;
        color: #FFF;
        text-align: center;
    }

    .slide-menu .toggle:before {
        content: "\f100";
        display: block;
        font-family: "icon";
        font-size: 20px;
        line-height: 40px;
    }

    .slide-menu.closed .toggle {
        right: -20px;
        width: 20px;
    }

    .slide-menu.closed .toggle:before {
        content: "\f101";
    }

    .slide-menu ul.l1 {
        max-height: 100%;
        overflow: auto;
    }

    .editor-content img.a-left45 {
        float: left !important;
        clear: left;
        width: auto !important;
        max-width: 45% !important;
        max-height: 90vh;
        margin: 0 4% 4% 0 !important;
    }

    .editor-content img.a-right45 {
        float: right !important;
        clear: right;
        width: auto !important;
        max-width: 45% !important;
        max-height: 90vh;
        margin: 0 0 4% 4% !important;
    }

    .editor-content img.a-100 {
        float: none !important;
        clear: left;
        width: 100% !important;
        max-height: 90vh;
        margin: 4% 0 !important;
    }

    .myDialog-content {
        padding: 15px;
    }

    .story-list .avatar {
        float: none;
    }

    .story-list .pad {
        margin-left: 0;
    }

    .community-box .content {
        width: auto;
        float: none;
        margin: 0;
    }

    .community-box .images {
        width: auto;
        float: none;
        margin: 0;
        margin-bottom: 1.3em;
    }

    .review-list .header .btn1 {
        padding: 0 5px;
        font-size: 11px;
    }

    .price_operation__data {
        overflow: auto;
        display: block;
        -webkit-overflow-scrolling:touch;
    }

    /*.price_operation__data tr td {
        display: none;
    }

    .price_operation__data tr td:first-child,
    .price_operation__data tr td:last-child {
        display: table-cell;
    }*/

    .header-menus {
        display: none !important;
    }

    #forum .forummessages .forummess .avatar-cnt {
        float: left;
        width: 25%;
    }

    #header .topbaner img {
        max-width: 100%;
    }

    .editor-content iframe {
        width: 100% !important;
        max-height: 100vh;
    }

    .wide.form label {
        text-align: left;
        float: none;
        display: block;
        width: auto;
    }

    .account_profile_update .operation-box {
        margin: 10px 0;
    }

    .account_profile_update .operation-box .operations .operation {
        float: none;
        width: auto;
    }

    .private_contacts__list .contact {
        display: block;
        width: auto;
        overflow: hidden;
    }

    .private_contacts__list .reply {
        font-size: 90%;
    }

    .account_video__grid .item {
        width: 100%;
        margin: 16px 0;
    }

    .photo_surgeon__grid .surgeon .pict_grid__item {
        width: 100%;
        float: none;
    }

    .breadcrumbs_menu {
        display: none;
    }

    select {
        max-width: 100%;
        box-sizing: border-box;
    }

    img {
        max-width: 100%;
    }

    .account_photo__grid .item {
        width: auto;
        float: none;
    }

    .community-box {
        margin-bottom: 2em;
    }

    .account_price_update__form a.btn2 {
        line-height: 34px;
        padding: 0 12px;
    }

    .adaptive-table {
        width: auto;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .account_price_items {
        font-size: 90%;
    }

    #mleft,
    #mright {
        display: none !important;
    }
}

@media screen and (max-width: 1023px) and (min-width: 568px) {
    .photo-box .item {
        width: 49%;
        float: right;
        box-sizing: border-box;
    }

    .photo-box .item:nth-of-type(2n+1) {
        clear: both;
        float: left;
    }

    .photo-box .footer {
        clear: both;
        float: none;
    }
}